
import { Options, Vue } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import store from '@/store';

@Options({
    components: {
    }
})  
export default class googleAutocompleteGetList extends Vue {
    
    @Prop({
        default: ""
    }) inputClass: string;

    @Prop({
        default: ""
    }) placeholderKey: string;

    @Prop({
        default: ""
    }) modelValue: string;

    @Prop({
        default: ""
    }) placeholderValue: string;

    @Prop({
        default: ""
    }) textToDeleteOnClick

    @Prop({
        default: function () { return [] }
    }) restrictToCountries: string[];

    //https://developers.google.com/maps/documentation/javascript/supported_types
    @Prop({
        default: () => []
    }) types: string[]

    localInput: string = "";
    autocomplete: any;
    language: string = "";

    timeoutHandler;
    @Watch('localInput')
    changedText(){
        clearTimeout(this.timeoutHandler);
        this.$emit("update:modelValue", this.localInput);
        
        if(this.localInput.length < 3 || this.localInput == this.$localizationService.getMyPositionText())
            return;

        var request = {
            input: this.localInput,
            componentRestrictions: {
                country: []
            },
            types: this.types,
            language: this.language
        }
        if(this.restrictToCountries && this.restrictToCountries.length > 0){
            request.componentRestrictions.country = this.restrictToCountries;
        }

        clearTimeout(this.timeoutHandler);
        this.timeoutHandler = setTimeout(() => {
            this.autocomplete.getPlacePredictions(request, this.emitResults);
        }, 700);
    }

    created(){
        this.localInput = this.modelValue;
        this.language = store.state.loggedUser.preferredCulture.substring(0, 2);
        console.log(this.language)
    }

    mounted(){
        this.autocomplete = new google.maps.places.AutocompleteService();
    }

    emitResults(data: VM.GoogleAutocompletePredictionResponseVM[], text){
        this.$emit('changeAddress', data)
    }

    deleteText(){
        if(!this.textToDeleteOnClick)
            return;

        if(this.localInput == this.textToDeleteOnClick)
            this.localInput = "";
    }
}
