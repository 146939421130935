class _UrlServices {

    openBlank(url: string){
        if(!window.cordova){
            window.open(url, "_blank");
            return;
        }

        window.cordova.InAppBrowser.open(url, '_system');
    }

    openSelf(url: string){
        if(!window.cordova){
            window.open(url, "_self");
            return;
        }

        window.cordova.InAppBrowser.open(url, '_system');
    }

}
export let UrlServices = new _UrlServices();